@font-face {
    font-family: 'ArabicBlack';
    src: local('ArabicBlack'), url('./assets/fonts/Arabic/ArabicBlack.woff') format('woff'),
        url('./assets/fonts/Arabic/ArabicBlack.ttf') format('truetype');




    ;
}


@font-face {
    font-family: 'ArabicRegualr';
    src: local('ArabicRegualr'), url('./assets/fonts/Arabic/ArabicRegualr.woff') format('truetype'),
        url('./assets/fonts/Arabic/ArabicRegualr.ttf') format('woff')
}


@font-face {
    font-family: 'ArabicBold';
    src: local('ArabicBold'), url('./assets/fonts/Arabic/ArabicBold.ttf') format('truetype'),
        url('./assets/fonts/Arabic/ArabicBold.woff') format('woff')
}




body,
html {
    margin: 0;
    padding: 0;
    height: 100%;
}


* {






    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto";







} 


